var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-map-world"
  }, [_c('Card', {
    attrs: {
      "padding": 0
    }
  }, [_c('p', {
    staticClass: "card-title",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("访问统计")]), _c('div', {
    staticStyle: {
      "height": "400px"
    },
    attrs: {
      "id": "wordMap"
    }
  })])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };